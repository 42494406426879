/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on April 20, 2020 */



@font-face {
    font-family: 'silka_monoblack';
    src: url('silkamono-black-webfont.eot');
    src: url('silkamono-black-webfont.eot?#iefix') format('embedded-opentype'),
         url('silkamono-black-webfont.woff2') format('woff2'),
         url('silkamono-black-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'silka_monoregular';
    src: url('silkamono-regular-webfont.eot');
    src: url('silkamono-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('silkamono-regular-webfont.woff2') format('woff2'),
         url('silkamono-regular-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'silka_monosemibold';
    src: url('silkamono-semibold-webfont.eot');
    src: url('silkamono-semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('silkamono-semibold-webfont.woff2') format('woff2'),
         url('silkamono-semibold-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'silka_monobold';
    src: url('silkamono-bold-webfont.eot');
    src: url('silkamono-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('silkamono-bold-webfont.woff2') format('woff2'),
         url('silkamono-bold-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'silka_monolight';
    src: url('silkamono-light-webfont.eot');
    src: url('silkamono-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('silkamono-light-webfont.woff2') format('woff2'),
         url('silkamono-light-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'silka_monomedium';
    src: url('silkamono-medium-webfont.eot');
    src: url('silkamono-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('silkamono-medium-webfont.woff2') format('woff2'),
         url('silkamono-medium-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'silka_monoextralight';
    src: url('silkamono-extralight-webfont.eot');
    src: url('silkamono-extralight-webfont.eot?#iefix') format('embedded-opentype'),
         url('silkamono-extralight-webfont.woff2') format('woff2'),
         url('silkamono-extralight-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'silka_monothin';
    src: url('silkamono-thin-webfont.eot');
    src: url('silkamono-thin-webfont.eot?#iefix') format('embedded-opentype'),
         url('silkamono-thin-webfont.woff2') format('woff2'),
         url('silkamono-thin-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}