.home-container-div{
  display: flex;
  flex-direction: column;
}

#softwareDescriptionDiv{
  margin-top: 15vmin;
  width: 100%;
}

#softwareDescriptionDiv #title p{
  max-width: 70vmin;
}

#subtitleSoftwareDiv{
  max-width: 70%;
  text-align: left;
  margin-top: 10vmin;
}

#imgContainer{
  display: flex;
  align-items: center;
  justify-content: center;
}

#imgContainer > div{
  max-width: 80%;
}

.list-item-desc{
  margin-left: 5.6vmin;
  margin-bottom: 1.6vmin;
  margin-top: 1.6vmin;
}

.list-item-btn{
  margin-left: 5.6vmin;
  margin-bottom: 5vmin;
  margin-top: 1.6vmin;
  min-width: 0 !important;
  width: auto;
  height: auto;
  padding: 1vmin;
}

.list-content{
}

#imgCustomer{
  height: 55vmin;
}

#imgCustomer .swiper{
  border-radius: 0 !important;
}


.subtitle-customer-div{
  padding: 12vmin 0;
  width: 60%;
}

.features-list{
  margin-top: 0 !important;
}

.block-btn{
  margin-bottom: 10vmin;
}

/* MOBILE */
@media only screen and (max-width: 768px) {

}

