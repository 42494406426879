
.font-36-600 {
    font-size: calc(12px + 2.8vmin);
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.39;
    letter-spacing: normal;
}

.font-36-500 {
    font-size: calc(12px + 2.8vmin);
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
}

.font-32-500 {
    font-size: calc(12px + 2.3vmin);
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
}

.font-28-500 {
    font-size: calc(12px + 1.8vmin);
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
}

.font-24-600 {
    font-size: calc(12px + 1.3vmin);
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
}

.font-24-500 {
    font-size: calc(12px + 1.3vmin);
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
}

.font-24 {
    font-size: calc(12px + 1.3vmin);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
}

.font-22-600 {
    font-size: calc(12px + 1.0vmin);
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.27;
    letter-spacing: normal;
}

.font-22 {
    font-size: calc(12px + 1.0vmin);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
}

.font-18 {
    font-size: calc(10px + 0.9vmin);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
}

.font-16-spacing {
    font-size: calc(10px + 0.7vmin);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: 2.56px;
}

.font-16 {
    font-size: calc(11px + 0.7vmin);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
}

.font-16-500 {
    font-size: calc(11px + 0.7vmin);
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    line-height: 1.5;
}

.font-14-500 {
    font-size: calc(10px + 0.5vmin);
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
}

.font-12 {
    font-size: calc(10px + 0.3vmin);
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
}



/* MOBILE */
@media only screen and (max-width: 768px) {


    .font-24-600{
        font-size: calc(15px + 1.3vmin);
    }

}

