.splash-container{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: #f9f9f3;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.splash-loading {
  width: 258px;
  height: 128px;
  display: block;
  margin: 48px;
}
.splash-loading__symbol {
  padding: 8px;
  animation: loading 3s infinite;
  border-radius: 5px;
}
.splash-loading__symbol .splash-img {
  display: block;
  max-width: 100%;
  animation: loading-icon 3s infinite;
}

.splash-img{
  background-image: url(../assets/images/4guest_logo.gif);
  height: 128px;
  width: 258px;
}

@keyframes loading {
  0% {
    transform: perspective(250px) rotateX(0deg) rotateY(0deg);
  }
  15% {
   /* background-color: #4caf50;*/
  }
  16% {
    /* background-color: #b44646; */
  }
  50% {
    transform: perspective(250px) rotateX(180deg) rotateY(0deg);
    /* background-color: #b44646; */
  }
  65% {
    /* background-color: #b44646; */
  }
  66% {
    /*background-color: #4caf50;*/
  }
  100% {
    transform: perspective(250px) rotateX(180deg) rotateY(-180deg);
  }
}
@keyframes loading-icon {
  0% {
    transform: perspective(250px) rotateX(0deg) rotateY(0deg);
  }
  15% {
    transform: perspective(250px) rotateX(0deg) rotateY(0deg);
  }
  16% {
    transform: perspective(250px) rotateX(180deg) rotateY(0deg);
  }
  50% {
    transform: perspective(250px) rotateX(180deg) rotateY(0deg);
  }
  65% {
    transform: perspective(250px) rotateX(180deg) rotateY(0deg);
  }
  66% {
    transform: perspective(250px) rotateX(180deg) rotateY(180deg);
  }
  100% {
    transform: perspective(250px) rotateX(180deg) rotateY(180deg);
  }
}
