.swiper-lazy{
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction{
    text-align: right !important;
    padding-right: 20px !important;
}

.swiper-pagination-bullets{
    top:16px !important;
    bottom: initial !important;
}


.images-slider .swiper{
    height: 100%;
    width: 100%;
}
#offer-item .swiper, #destination-item .swiper{
    border-radius: 40px;
}

.iframe-youtube-cover{
    box-sizing: border-box;
    height: 56.25vw;
    left: 50%;
    min-height: 100%;
    min-width: 100%;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    border: none;
    background-clip: border-box,padding-box;
    border-radius: 24px;
}

.iframe-youtube-cover-overlay{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
}

.uploaded-video-display{
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    max-height: initial;
}


/* MOBILE */
@media only screen and (max-width: 768px) {
    .iframe-youtube-cover-overlay{
        left: 65%;
    }


    .iframe-youtube-cover{
        width: 335%;
        height: 135%;
        object-fit: cover;
        border: none;
    }

    #offer-item .swiper, #destination-item .swiper {
        border-radius: 20px;
    }
}
