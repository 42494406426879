$light-grey: #f9f9f3;
$white: #ffffff;
$pale-turquoise: #bbf9d9;
$pale-olive: #cacf9d;
$hunter-green: #1d1e06;
$pale-olive-light : #f6f6f1;
$cappuccino: #F3EFD8;

$text-color-light: #f9f9f3;
$text-color-dark: #2e3126;
$color-icon: #2e3126;
$gradient-accent: rgba(156, 24, 11, 0.68);
$gradient-accent_2: #2e3126;
$color-accent: #28c8a1;

*{
  /*color: $hunter-green;*/
}

.overlay-popup{

  background-color: rgba(0,0,0,0.5);
}

.toolbar > div.menu-div, #lists-container-home>.bullet-container:nth-child(even), .btn {
  background: $pale-turquoise;
  color: $hunter-green;
}

.menu-voice-div{
  color: $hunter-green;
  text-decoration: none;
}

.menu-voice-div:hover, .menu-item-selected{
  color: $hunter-green;
}

.toolbar{
  backdrop-filter: blur(16px);

  background-color: $light-grey;
}

.toolbar > div.menu-div{

}


.dark-btn{
  background: $hunter-green;
  color: $text-color-light;
}

.accent-btn, {
  background: $color-accent;
  color: $text-color-light;
}

.swiper-pagination-bullet-active{
  background: $color-accent !important;
}

.primary-btn{
  background: $light-grey;
  color: $text-color-light;
}

.header-home-div > div > p.title, .header-home-div > div > p.subtitle{
  color: $text-color-light;
}

.accent-text{
  color: $light-grey;
}

.contacts-container-div, .popup-container{
  background: $white;
}

.gallery-title,.contacts-container-div > .title{
  color: $hunter-green;
}

.contacts-container-div > .subtitle{
  color: $light-grey;
}

.gallery-title-red{
  color: $gradient-accent_2;
}

.gallery-subtitle{
  color: $light-grey;
}

.categories-labels a{
  color: $light-grey;
}

.header-home-div:after{
  background: linear-gradient(to top, rgba(255,0,0,0) 0%, $light-grey 100%);
}

@keyframes photoSlide {
  from {
    margin-left: 0;
  }

  to {
    margin-left: 10%;
  }
}

::selection {
  color: $text-color-light; /* WebKit/Blink Browsers */
  background: $hunter-green;
}
::-moz-selection {
  color: $text-color-light;
  background: $hunter-green;
}

.icon{
  color: $color-icon;
}


*::-webkit-scrollbar-thumb {
  background-color: $light-grey;
}

.contact-us-div{
  background-color: $light-grey;
}

.contact-description{
  color: $text-color-light;
}

.swiper-pagination-bullet{
  background: $white;
  opacity: 1;
  -webkit-box-shadow: 1px 2px 8px -2px #000000;
  box-shadow: 1px 2px 8px -2px #000000;
}

.swiper-pagination-bullet-active{
  background-color: $light-grey;
}

.whitebox-info{
  background: $white;
}

.whitebox-info *{
  color: $hunter-green;
}

.info-header, .info-header *{
  color: $light-grey;
}

.newsletter-div{
  background-color: $hunter-green;
}

.contacts-div {
  background-color: $hunter-green;
  color: $text-color-light;
}

.contacts-div *{
  color: $text-color-light;
}


.news-description{
  color: $light-grey;
}

.input{
  background: $white;
}

.check-div{
  color: $text-color-light;
}


.contacts-detail{

}

.checkbox{
  background: transparent;
}

.label-filter{
  color: $hunter-green;
}

.sub-categories-labels-container{
  background-color:rgba(#eee,0.8);
}

.selected-category{
  color: $hunter-green !important;
}

.white-btn{
  background: $white;
  color: $text-color-dark;
}

.contact-span{
  color: $hunter-green;
}

.dialog-title{
  color: $hunter-green;
}

.MuiOutlinedInput-notchedOutline{
  border-color: $light-grey !important;
  border-width: 1px !important;
}

.Mui-focused{
  color: $light-grey !important;
}

.MuiIconButton-root{
  color: $light-grey !important;
}

.MuiSvgIcon-root{
  color: $light-grey !important;
}

.dialog-ok-button{
  background-color: $hunter-green;
  color: #fff;
}

.close-dialog-label{
  color: $light-grey
}

.label-filter .MuiFormControlLabel-label, .checkbox-label-filter .MuiFormControlLabel-label{
  color: $light-grey
}

.link{
  color: $light-grey;
  font-weight: bold;
}

.link:hover{
  color: $hunter-green
}

.square-containers-header{
  background: $white;
}

.square-containers-header .gallery-title{
  color: $color-accent;
}

.arrow{
  color: $light-grey !important;
}

div.notFound{
  color: $hunter-green;
}


.toolbar .icon-menu{
  color: $hunter-green;
  font-size: 2em;
}

.mobile-menu{
  background-color: $light-grey;
  color: $text-color-light;
}

.mobile-menu-voice-div{
  border-color: $hunter-green;
  color: $hunter-green;
}

.mobile-menu{
  border-color: $hunter-green;
}

.imgtext-div:nth-child(even) > .action-container-div > .desc-div, #primary-btns-container>button:last-child{
  background: $pale-olive;
  color: $text-color-dark;
}

.imgtext-div:nth-child(even) > .action-container-div > .action-div, #secondary-btns-container .btn{
  background: $light-grey;
}

.menu-item-selected{
    box-shadow: inset 0 -3px 0 $hunter-green;
}

.imgtext-div:nth-child(odd) > .action-container-div > .desc-div, #news .btn{
  background: $hunter-green;
  color: $text-color-light !important;
}

.imgtext-div:nth-child(odd) > .action-container-div > .action-div{
  background: $pale-turquoise;
}


/* Track */
::-webkit-scrollbar-track {
  background: $hunter-green;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $hunter-green;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $hunter-green;
}

.container{
  background: $light-grey;
}

.tab-header > div{
  background: $white;
  color: $text-color-dark;
}

.tab-header > div.tab-products:hover{
  background: $pale-olive ;
}

.background-light{
  background: $light-grey;
}

.info-container-div{
  background: $hunter-green;
}

.info-container-div > div.info-div > p{
  color: white;
}


.icon-white{
  color: white;
}

.mail-to-color{
  color:#27c8a1;
}

.action-div{
  background: $pale-turquoise;
}

.action-div-dark{
  background: $hunter-green;
  color: white;
}

.customer-container-div{
  background: $pale-turquoise;
}

#news{
  background: $pale-turquoise;
}

#contacts{
  background: $pale-olive;
}

#contacts{
  color: $hunter-green;
}

#contacts *{
  color: $hunter-green;
}

.news-container-div{
  background: $pale-olive-light;
}


.news-cell-div:hover, #software-categories, #servizi-gallery{
  background-color: $white;
}

.news-cell-div,  .customer-service-support-flex-container .software-cell-div:hover{
  background: white;
}

.news-div{
  background: $pale-turquoise;
}

.gallery-container, #b2c-slider{
  background: $hunter-green;
}


.news-date, #news-page .intro-test-p, #numbers-list, .back-btn > *, #b2c-slider .about-titles, #contact-page .intro-test-p,
.toolbar .icon-menu{
  color: $pale-olive;
}

.mobile-menu-voice-div{
  color: $hunter-green;
  border-color: $pale-olive !important;
  text-decoration: none;
  outline: none;
}

.back-bar-div{
  background: $pale-turquoise;
}

.info-software-div,.info-news-div{
  background: white;
}

.news-background{
  background: $pale-turquoise;
}

.news-detail-div{
  background: $pale-olive-light;
}

.news-detail-div > div{
  background: white;
}


/* TABLET */
@media only screen and (max-width: 768px) {

  .menu-item-selected {
    box-shadow: initial;
    font-weight: bold;
  }

}
