.news-detail-div{
    padding-top: 7vmin;
    padding-bottom: 10vmin;
}

.news-detail-div > div{
    border-radius: 24px;
}



.section-description{
    max-width: 1000px;
}

#news-media{
    aspect-ratio: 16/9;
    border-radius: 24px;
}

.sections-container-div {
    display: flex;
    flex-direction: column;
    gap: 8vmin;
    margin: 8vmin 0;
}

.news-grid .info{
 padding: 24px !important;
}

.info{
    text-align: left;
    flex: 1;
    padding: 3vmin;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 32px;
}

#news-media .swiper-lazy{
    border-radius: 24px;
}

.news-media-container{
    padding-bottom: 0;
}

/* MOBILE */
@media only screen and (max-width: 768px) {

    .news-media-container{
        padding-top: 6vmin;
        padding-bottom: 2vmin;
    }

    .info{
        gap: 16px;
    }

    .sections-container-div{
     padding-left: 0;
        padding-right: 0;
    }
}


