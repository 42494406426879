@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?wv0vrv');
  src:  url('fonts/icomoon.eot?wv0vrv#iefix') format('embedded-opentype'),
    url('fonts/icomoon.woff2?wv0vrv') format('woff2'),
    url('fonts/icomoon.ttf?wv0vrv') format('truetype'),
    url('fonts/icomoon.woff?wv0vrv') format('woff'),
    url('fonts/icomoon.svg?wv0vrv#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-twitter:before {
  content: "\ea75";
}
.icon-facebook:before {
  content: "\ea72";
}
.icon-instagram:before {
  content: "\ea73";
}
.icon-linkedin:before {
  content: "\ea74";
}
.icon-smac_digitale:before {
  content: "\ea6e";
}
.icon-smac_fisica:before {
  content: "\ea6f";
}
.icon-smac_no:before {
  content: "\ea70";
}
.icon-smac:before {
  content: "\ea71";
}
.icon-pagamento_confermato:before {
  content: "\ea68";
}
.icon-pagamento_eliminato:before {
  content: "\ea69";
}
.icon-pagamento_modifica:before {
  content: "\ea6a";
}
.icon-pagamento_non_confermato:before {
  content: "\ea6b";
}
.icon-rispondi_evadi:before {
  content: "\ea6c";
}
.icon-messaggio_persona:before {
  content: "\ea67";
}
.icon-barra_stato:before {
  content: "\ea6d";
}
.icon-invio:before {
  content: "\ea66";
}
.icon-elimina_utente:before {
  content: "\ea65";
}
.icon-down:before {
  content: "\ea63";
}
.icon-up:before {
  content: "\ea64";
}
.icon-accesso:before {
  content: "\e900";
}
.icon-acquisisci_immagine:before {
  content: "\e901";
}
.icon-acquisito_sdi:before {
  content: "\e902";
}
.icon-acquisti_stilizzato:before {
  content: "\e903";
}
.icon-acquisti:before {
  content: "\e904";
}
.icon-adatta:before {
  content: "\e905";
}
.icon-adattare:before {
  content: "\e906";
}
.icon-aggiungi:before {
  content: "\e907";
}
.icon-airplane:before {
  content: "\e908";
}
.icon-alert_scadenza:before {
  content: "\e909";
}
.icon-alert:before {
  content: "\e90a";
}
.icon-allegati:before {
  content: "\e90b";
}
.icon-anagrafiche_femmina_maschio:before {
  content: "\e90c";
}
.icon-anagrafiche_maschio_femmina:before {
  content: "\e90d";
}
.icon-anagrafiche:before {
  content: "\e90e";
}
.icon-aperitivo:before {
  content: "\e90f";
}
.icon-api:before {
  content: "\e910";
}
.icon-apps:before {
  content: "\e911";
}
.icon-apri_archivio:before {
  content: "\e912";
}
.icon-apri_cartelle:before {
  content: "\e913";
}
.icon-apri_cassetto:before {
  content: "\e914";
}
.icon-apri_filtri:before {
  content: "\e915";
}
.icon-apri_pannello:before {
  content: "\e916";
}
.icon-apri_primanota:before {
  content: "\e917";
}
.icon-archivia:before {
  content: "\e918";
}
.icon-assegna_posti:before {
  content: "\e919";
}
.icon-assenza:before {
  content: "\e91a";
}
.icon-assicurazione:before {
  content: "\e91b";
}
.icon-associa_utenti:before {
  content: "\e91c";
}
.icon-atm:before {
  content: "\e91d";
}
.icon-attention:before {
  content: "\e91e";
}
.icon-attesa_cancellazione:before {
  content: "\e91f";
}
.icon-attesa_invio:before {
  content: "\e920";
}
.icon-attesa_risposta:before {
  content: "\e921";
}
.icon-attivita:before {
  content: "\e922";
}
.icon-automatismi:before {
  content: "\e923";
}
.icon-avanti:before {
  content: "\e924";
}
.icon-aziende:before {
  content: "\e925";
}
.icon-badge:before {
  content: "\e926";
}
.icon-banca_ore:before {
  content: "\e927";
}
.icon-banconote:before {
  content: "\e928";
}
.icon-bank:before {
  content: "\e929";
}
.icon-barcode:before {
  content: "\e92a";
}
.icon-blocca:before {
  content: "\e92b";
}
.icon-booking:before {
  content: "\e92c";
}
.icon-bottone:before {
  content: "\e92d";
}
.icon-bozza:before {
  content: "\e92e";
}
.icon-brioches:before {
  content: "\e92f";
}
.icon-business_man:before {
  content: "\e930";
}
.icon-business_woman:before {
  content: "\e931";
}
.icon-busta_aperta:before {
  content: "\e932";
}
.icon-busta:before {
  content: "\e933";
}
.icon-buste_paga:before {
  content: "\e934";
}
.icon-calcola_percentuale:before {
  content: "\e935";
}
.icon-calcolatrice:before {
  content: "\e936";
}
.icon-calendario_documento:before {
  content: "\e937";
}
.icon-calendario_elimina:before {
  content: "\e938";
}
.icon-calendario_esportazione:before {
  content: "\e939";
}
.icon-calendario_giorno:before {
  content: "\e93a";
}
.icon-calendario_importazione:before {
  content: "\e93b";
}
.icon-calendario_mese:before {
  content: "\e93c";
}
.icon-calendario_modifiche:before {
  content: "\e93d";
}
.icon-calendario_scadenza:before {
  content: "\e93e";
}
.icon-calendario_settimana:before {
  content: "\e93f";
}
.icon-calendario:before {
  content: "\e940";
}
.icon-camion:before {
  content: "\e941";
}
.icon-cancella_tutto:before {
  content: "\e942";
}
.icon-cancella:before {
  content: "\e943";
}
.icon-car_crash:before {
  content: "\e944";
}
.icon-car_rental:before {
  content: "\e945";
}
.icon-cards:before {
  content: "\e946";
}
.icon-caricato:before {
  content: "\e947";
}
.icon-carousel:before {
  content: "\e948";
}
.icon-carrello:before {
  content: "\e949";
}
.icon-cartella_aperta:before {
  content: "\e94a";
}
.icon-cartella_chiusa:before {
  content: "\e94b";
}
.icon-cassa:before {
  content: "\e94c";
}
.icon-cassaforte:before {
  content: "\e94d";
}
.icon-cerca_documento:before {
  content: "\e94e";
}
.icon-cerca:before {
  content: "\e94f";
}
.icon-certificazioni:before {
  content: "\e950";
}
.icon-chiudi_cartelle:before {
  content: "\e951";
}
.icon-chiudi_pannello:before {
  content: "\e952";
}
.icon-chiudi:before {
  content: "\e953";
}
.icon-circolari:before {
  content: "\e954";
}
.icon-clessidra_piena:before {
  content: "\e955";
}
.icon-clessidra:before {
  content: "\e956";
}
.icon-close:before {
  content: "\e957";
}
.icon-cloud:before {
  content: "\e958";
}
.icon-cocktail:before {
  content: "\e959";
}
.icon-comprimere:before {
  content: "\e95a";
}
.icon-comprimi:before {
  content: "\e95b";
}
.icon-comunicazioni:before {
  content: "\e95c";
}
.icon-condividi:before {
  content: "\e95d";
}
.icon-confermato:before {
  content: "\e95e";
}
.icon-connection:before {
  content: "\e95f";
}
.icon-conservato:before {
  content: "\e960";
}
.icon-contabilizzato:before {
  content: "\e961";
}
.icon-contatti:before {
  content: "\e962";
}
.icon-controlla_documento:before {
  content: "\e963";
}
.icon-controllo_pratica:before {
  content: "\e964";
}
.icon-copia:before {
  content: "\e965";
}
.icon-crea_pieno:before {
  content: "\e966";
}
.icon-crea_vuoto:before {
  content: "\e967";
}
.icon-crea:before {
  content: "\e968";
}
.icon-credit_card:before {
  content: "\e969";
}
.icon-crescente:before {
  content: "\e96a";
}
.icon-csv:before {
  content: "\e96b";
}
.icon-customer_care:before {
  content: "\e96c";
}
.icon-da_programma:before {
  content: "\e96d";
}
.icon-dati_fondiss:before {
  content: "\e96e";
}
.icon-dati_retributivi:before {
  content: "\e96f";
}
.icon-ddt:before {
  content: "\e970";
}
.icon-decrescente:before {
  content: "\e971";
}
.icon-descrizione:before {
  content: "\e972";
}
.icon-diviso:before {
  content: "\e973";
}
.icon-documenti:before {
  content: "\e974";
}
.icon-documento_bloccato:before {
  content: "\e975";
}
.icon-documento_cancellato:before {
  content: "\e976";
}
.icon-documento_cliente:before {
  content: "\e977";
}
.icon-documento_eliminato:before {
  content: "\e978";
}
.icon-documento_fornitore:before {
  content: "\e979";
}
.icon-documento_in_distinta:before {
  content: "\e97a";
}
.icon-documento_persona:before {
  content: "\e97b";
}
.icon-documento_scartato:before {
  content: "\e97c";
}
.icon-documento_vuoto:before {
  content: "\e97d";
}
.icon-documento:before {
  content: "\e97e";
}
.icon-download_documento:before {
  content: "\e97f";
}
.icon-download_multiplo:before {
  content: "\e980";
}
.icon-download:before {
  content: "\e981";
}
.icon-drag_orizzontale:before {
  content: "\e982";
}
.icon-drag_verticale:before {
  content: "\e983";
}
.icon-drag:before {
  content: "\e984";
}
.icon-duplicate:before {
  content: "\e985";
}
.icon-elimina_documento:before {
  content: "\e986";
}
.icon-elimina_sostituisci:before {
  content: "\e987";
}
.icon-errore_ricezione:before {
  content: "\e988";
}
.icon-escludi:before {
  content: "\e989";
}
.icon-esporta_calendario:before {
  content: "\e98a";
}
.icon-esporta:before {
  content: "\e98b";
}
.icon-euro:before {
  content: "\e98c";
}
.icon-fascicolo_dipendente:before {
  content: "\e98d";
}
.icon-fattura_oggi:before {
  content: "\e98e";
}
.icon-fattura_ritardo:before {
  content: "\e98f";
}
.icon-fatturato:before {
  content: "\e990";
}
.icon-fee:before {
  content: "\e991";
}
.icon-femmina:before {
  content: "\e992";
}
.icon-ferie:before {
  content: "\e993";
}
.icon-filtri_avanzati:before {
  content: "\e994";
}
.icon-filtri:before {
  content: "\e995";
}
.icon-firma_non_valida:before {
  content: "\e996";
}
.icon-firma:before {
  content: "\e997";
}
.icon-foglio_stile:before {
  content: "\e998";
}
.icon-footer:before {
  content: "\e999";
}
.icon-freccia_down:before {
  content: "\e99a";
}
.icon-freccia_left:before {
  content: "\e99b";
}
.icon-freccia_right:before {
  content: "\e99c";
}
.icon-freccia_up:before {
  content: "\e99d";
}
.icon-gas_pump:before {
  content: "\e99e";
}
.icon-gestione_ferie:before {
  content: "\e99f";
}
.icon-gestisci_account:before {
  content: "\e9a0";
}
.icon-giornalino:before {
  content: "\e9a1";
}
.icon-google:before {
  content: "\e9a2";
}
.icon-gps:before {
  content: "\e9a3";
}
.icon-guida:before {
  content: "\e9a4";
}
.icon-handshake:before {
  content: "\e9a5";
}
.icon-health:before {
  content: "\e9a6";
}
.icon-help:before {
  content: "\e9a7";
}
.icon-home_piena:before {
  content: "\e9a8";
}
.icon-home:before {
  content: "\e9a9";
}
.icon-hotel:before {
  content: "\e9aa";
}
.icon-immagine:before {
  content: "\e9ab";
}
.icon-import_export:before {
  content: "\e9ac";
}
.icon-importa_calendario:before {
  content: "\e9ad";
}
.icon-importa_persone:before {
  content: "\e9ae";
}
.icon-importa:before {
  content: "\e9af";
}
.icon-imposta_fissa:before {
  content: "\e9b0";
}
.icon-imposta_notifica:before {
  content: "\e9b1";
}
.icon-impostazioni_documento:before {
  content: "\e9b2";
}
.icon-impostazioni_orario:before {
  content: "\e9b3";
}
.icon-impostazioni_persona:before {
  content: "\e9b4";
}
.icon-impostazioni_posizione:before {
  content: "\e9b5";
}
.icon-impostazioni:before {
  content: "\e9b6";
}
.icon-incasso:before {
  content: "\e9b7";
}
.icon-indietro:before {
  content: "\e9b8";
}
.icon-indirizzo:before {
  content: "\e9b9";
}
.icon-industrie:before {
  content: "\e9ba";
}
.icon-info:before {
  content: "\e9bb";
}
.icon-ingresso_azienda:before {
  content: "\e9bc";
}
.icon-inserisci_incasso:before {
  content: "\e9bd";
}
.icon-inserisci:before {
  content: "\e9be";
}
.icon-insert_documento:before {
  content: "\e9bf";
}
.icon-integrazioni:before {
  content: "\e9c0";
}
.icon-invia:before {
  content: "\e9c1";
}
.icon-invio_documento:before {
  content: "\e9c2";
}
.icon-kate:before {
  content: "\e9c3";
}
.icon-lavorazioni_dettaglio:before {
  content: "\e9c4";
}
.icon-link:before {
  content: "\e9c5";
}
.icon-lista_documento:before {
  content: "\e9c6";
}
.icon-lista_filtri:before {
  content: "\e9c7";
}
.icon-lista_regalo:before {
  content: "\e9c8";
}
.icon-lista:before {
  content: "\e9c9";
}
.icon-log:before {
  content: "\e9ca";
}
.icon-login:before {
  content: "\e9cb";
}
.icon-logout:before {
  content: "\e9cc";
}
.icon-maggiorazione:before {
  content: "\e9cd";
}
.icon-mail_aperta:before {
  content: "\e9ce";
}
.icon-mail:before {
  content: "\e9cf";
}
.icon-manda_in_revisione:before {
  content: "\e9d0";
}
.icon-manuale:before {
  content: "\e9d1";
}
.icon-maschio:before {
  content: "\e9d2";
}
.icon-meno_piena:before {
  content: "\e9d3";
}
.icon-meno_uno:before {
  content: "\e9d4";
}
.icon-meno_vuota:before {
  content: "\e9d5";
}
.icon-meno:before {
  content: "\e9d6";
}
.icon-menu_website:before {
  content: "\e9d7";
}
.icon-menu:before {
  content: "\e9d8";
}
.icon-merce:before {
  content: "\e9d9";
}
.icon-messaggio:before {
  content: "\e9da";
}
.icon-modifica_ora:before {
  content: "\e9db";
}
.icon-modifica_scadenza:before {
  content: "\e9dc";
}
.icon-modifica:before {
  content: "\e9dd";
}
.icon-modulistica:before {
  content: "\e9de";
}
.icon-moltiplica:before {
  content: "\e9df";
}
.icon-monete:before {
  content: "\e9e0";
}
.icon-monitor:before {
  content: "\e9e1";
}
.icon-monumento:before {
  content: "\e9e2";
}
.icon-more:before {
  content: "\e9e3";
}
.icon-nascondi:before {
  content: "\e9e4";
}
.icon-natura_scadenza:before {
  content: "\e9e5";
}
.icon-news:before {
  content: "\e9e6";
}
.icon-no_conservata:before {
  content: "\e9e7";
}
.icon-no_controllo_pratica:before {
  content: "\e9e8";
}
.icon-no_wi_fi:before {
  content: "\e9e9";
}
.icon-non_confermato:before {
  content: "\e9ea";
}
.icon-note:before {
  content: "\e9eb";
}
.icon-notifica:before {
  content: "\e9ec";
}
.icon-nr_documento:before {
  content: "\e9ed";
}
.icon-nuova_attivita:before {
  content: "\e9ee";
}
.icon-nuova_segnalazione:before {
  content: "\e9ef";
}
.icon-nuovo_documento:before {
  content: "\e9f0";
}
.icon-nuovo_orario:before {
  content: "\e9f1";
}
.icon-nuovo_servizio:before {
  content: "\e9f2";
}
.icon-operaio:before {
  content: "\e9f3";
}
.icon-orologio_impostazioni:before {
  content: "\e9f4";
}
.icon-orologio:before {
  content: "\e9f5";
}
.icon-others:before {
  content: "\e9f6";
}
.icon-pagamento_ritardo:before {
  content: "\e9f7";
}
.icon-pagamento:before {
  content: "\e9f8";
}
.icon-pannelli:before {
  content: "\e9f9";
}
.icon-paragrafo:before {
  content: "\e9fa";
}
.icon-parco_divertimenti:before {
  content: "\e9fb";
}
.icon-password:before {
  content: "\e9fc";
}
.icon-percentuale:before {
  content: "\e9fd";
}
.icon-persona:before {
  content: "\e9fe";
}
.icon-personalizza:before {
  content: "\e9ff";
}
.icon-piano_dei_conti:before {
  content: "\ea00";
}
.icon-piu_uno:before {
  content: "\ea01";
}
.icon-piu:before {
  content: "\ea02";
}
.icon-posta_arrivo:before {
  content: "\ea03";
}
.icon-posta_uscita:before {
  content: "\ea04";
}
.icon-pratica_annullata:before {
  content: "\ea05";
}
.icon-pratica_nuova:before {
  content: "\ea06";
}
.icon-pratica_opzionata:before {
  content: "\ea07";
}
.icon-pratica_saldata:before {
  content: "\ea08";
}
.icon-preferiti_cuore_pieno:before {
  content: "\ea09";
}
.icon-preferiti_cuore:before {
  content: "\ea0a";
}
.icon-preferiti_piena:before {
  content: "\ea0b";
}
.icon-preferiti:before {
  content: "\ea0c";
}
.icon-prima_pagina:before {
  content: "\ea0d";
}
.icon-primo:before {
  content: "\ea0e";
}
.icon-print:before {
  content: "\ea0f";
}
.icon-produzione:before {
  content: "\ea10";
}
.icon-qr_code:before {
  content: "\ea11";
}
.icon-qualifica:before {
  content: "\ea12";
}
.icon-rata_pagata_in_parte:before {
  content: "\ea13";
}
.icon-rata_pagata:before {
  content: "\ea14";
}
.icon-report_referenti:before {
  content: "\ea15";
}
.icon-report_roominglist:before {
  content: "\ea16";
}
.icon-report:before {
  content: "\ea17";
}
.icon-ricevi_documento:before {
  content: "\ea18";
}
.icon-ricevi:before {
  content: "\ea19";
}
.icon-richiesta_scarto_ut:before {
  content: "\ea1a";
}
.icon-riempiposto:before {
  content: "\ea1b";
}
.icon-riepilogo:before {
  content: "\ea1c";
}
.icon-rifiutata_ut:before {
  content: "\ea1d";
}
.icon-righe_fattura:before {
  content: "\ea1e";
}
.icon-ristorante:before {
  content: "\ea1f";
}
.icon-ritardo:before {
  content: "\ea20";
}
.icon-roominglist:before {
  content: "\ea21";
}
.icon-salva:before {
  content: "\ea22";
}
.icon-salvadanaio:before {
  content: "\ea23";
}
.icon-sblocca:before {
  content: "\ea24";
}
.icon-scadenza_documento:before {
  content: "\ea25";
}
.icon-scadenze:before {
  content: "\ea26";
}
.icon-scadenziario:before {
  content: "\ea27";
}
.icon-scandenza_attiva:before {
  content: "\ea28";
}
.icon-scandenza_passiva:before {
  content: "\ea29";
}
.icon-sconto:before {
  content: "\ea2a";
}
.icon-scontrino:before {
  content: "\ea2b";
}
.icon-scrivi:before {
  content: "\ea2c";
}
.icon-segnalazione:before {
  content: "\ea2d";
}
.icon-selezionata_quadrata:before {
  content: "\ea2e";
}
.icon-selezionata_tonda:before {
  content: "\ea2f";
}
.icon-selezione_quadrata:before {
  content: "\ea30";
}
.icon-selezione_tonda:before {
  content: "\ea31";
}
.icon-services:before {
  content: "\ea32";
}
.icon-servizio_codificato:before {
  content: "\ea33";
}
.icon-settings:before {
  content: "\ea34";
}
.icon-shopping:before {
  content: "\ea35";
}
.icon-smartphone_android:before {
  content: "\ea36";
}
.icon-smartphone_iphone:before {
  content: "\ea37";
}
.icon-sottotitolo:before {
  content: "\ea38";
}
.icon-spunta_piena:before {
  content: "\ea39";
}
.icon-spunta:before {
  content: "\ea3a";
}
.icon-stato_completato:before {
  content: "\ea3b";
}
.icon-stato_documento:before {
  content: "\ea3c";
}
.icon-storico_modifiche:before {
  content: "\ea3d";
}
.icon-sveglia:before {
  content: "\ea3e";
}
.icon-svuota_documento:before {
  content: "\ea3f";
}
.icon-t_shirt:before {
  content: "\ea40";
}
.icon-tablet:before {
  content: "\ea41";
}
.icon-tasse:before {
  content: "\ea42";
}
.icon-telefono:before {
  content: "\ea43";
}
.icon-testata:before {
  content: "\ea44";
}
.icon-ticket_evento:before {
  content: "\ea45";
}
.icon-timbratura:before {
  content: "\ea46";
}
.icon-tipo_merce:before {
  content: "\ea47";
}
.icon-titoletto:before {
  content: "\ea48";
}
.icon-titolo:before {
  content: "\ea49";
}
.icon-tour:before {
  content: "\ea4a";
}
.icon-traghetto:before {
  content: "\ea4b";
}
.icon-trash:before {
  content: "\ea4c";
}
.icon-treno:before {
  content: "\ea4d";
}
.icon-uguale:before {
  content: "\ea4e";
}
.icon-ultima_pagina:before {
  content: "\ea4f";
}
.icon-ultimo:before {
  content: "\ea50";
}
.icon-update:before {
  content: "\ea51";
}
.icon-upload_download:before {
  content: "\ea52";
}
.icon-upload:before {
  content: "\ea53";
}
.icon-uscita_azienda:before {
  content: "\ea54";
}
.icon-utilities:before {
  content: "\ea55";
}
.icon-utility_acqua:before {
  content: "\ea56";
}
.icon-utility_gas:before {
  content: "\ea57";
}
.icon-utility_luce:before {
  content: "\ea58";
}
.icon-utility:before {
  content: "\ea59";
}
.icon-vaccinazioni:before {
  content: "\ea5a";
}
.icon-vendite_stilizzato:before {
  content: "\ea5b";
}
.icon-vendite:before {
  content: "\ea5c";
}
.icon-video:before {
  content: "\ea5d";
}
.icon-visibilita:before {
  content: "\ea5e";
}
.icon-wi_fi:before {
  content: "\ea5f";
}
.icon-xml_rsm:before {
  content: "\ea60";
}
.icon-xml_trasformare:before {
  content: "\ea61";
}
.icon-youtube:before {
  content: "\ea62";
}
