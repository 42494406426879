
/*BASE STYLE*/

.flex{
    display: flex;
}

.flex-direction_row{
    flex-direction: row;
}

.flex-direction_column{
    flex-direction: column;
}

.align-items_center{
    align-items: center;
}

.justify-content_start{
    justify-content: flex-start;
}

.justify-content_end{
    justify-content: flex-end;
}

.justify-content_center{
    justify-content: center;
}

.flex_1{
    flex: 1;
}

.flex_2{
    flex: 2;
}

.width_100{
    width: 100%;
}

.height_100{
    height: 100%;
}

.text-align_left{
    text-align: left;
}

.text-align_right{
    text-align: right;
}

.text-align_center{
    text-align: center;
}

.gap_3vmin{
    gap: 3vmin;
}

.gap_16px{
    gap: 16px;
}
.gap_40px{
    gap: 40px;
}


.padded {
    padding-right: 8vmin;
    padding-left: 8vmin;
}

.padded-vertical{
    padding-top: 15vmin;
    padding-bottom: 15vmin;
}

.padded-vertical-less{
    padding-top: 7vmin;
    padding-bottom: 7vmin;
}

.padded-vertical-less-less{
    padding-top: 4vmin;
    padding-bottom: 4vmin;
}

.radius-25  .swiper{
    border-radius: 25px;
}

.padding-bottom_0{
    padding-bottom: 0 !important;
}

.flex-container_1{
    display: flex;
}

.flex-container_1 > div{
    flex: 1;
    text-align: left;
}

.flex-direction_row-column{
    flex-direction: row;
}

.btn{
    min-width: 115px;
    border-radius: 12px;
    height: 5vmin;
    min-height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    box-shadow: 0;
    cursor: pointer;
    width: fit-content;
    height: 40px;
    padding-left:2vmin;
    padding-right: 2vmin;
    gap: 8px;
}

.btn a {
    text-decoration: none !important;
    color: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
}

.btn i{
    font-size: calc(22px);
    margin-left: -6px;
    margin-right: -6px;
}

.line-height_1-6{
    line-height: 1.55;
}

/* TABLET */
@media only screen and (max-width: 1024px) {
    .padded {
        padding-right: 6vmin;
        padding-left: 6vmin;
    }

    .flex-direction_row-column{
        flex-direction: column;
    }
}

/* MOBILE */
@media only screen and (max-width: 768px) {
    .hide-mobile{
        display: none;
    }

    .padded-vertical{
        padding-top: 12vmin;
        padding-bottom: 12vmin;
    }

}
