.overlay-popup{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index:99;
    display: flex;
    align-items: center;
    justify-content: center;
}

.popup-container{
    width: 80%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 3vmin;
    border-radius: 24px;
    aspect-ratio: 16/9;
    position: relative;
}

.popup-container .icon-chiudi{
    position: absolute;
    top: 24px;
    right: 24px;
    font-size: 32px;
    z-index: 2;
    background: white;
    padding: 7px;
    border-bottom-left-radius: 24px;
    cursor: pointer;

}

.popup-container .images-slider{
    height: 100%;
    width: 100%;
}


/* MOBILE */
@media only screen and (max-width: 768px) {
    .popup-container .icon-chiudi{
        top: 4px;
        right: 4px;
        border-radius: 24px;
    }
}
