.news-cell-div {
    border-radius: 24px;
    cursor: url(data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" stroke="%23000000" fill="%23000000" opacity="0.9" width="32px" height="32px" viewBox="0 0 10.04 10.04"><circle cx="5.02" cy="5.02" r="4.52"/></svg>) 8 8,none;
    display: flex;
    flex-direction: column;
    grid-column: span 2;
}
#news-detail-page .news-cell-div{
    padding: 5vmin;
}

.news-cell-div > div.img{
    aspect-ratio: 16/9;
    border-radius: 24px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.news-cell-div > div.info{
    flex: 1;
    padding: 3vmin;
    display: flex;
    flex-direction: column;
    align-items: start;
}

.news-cell-div > div.info >*{
    max-width: 80%;
}



.read-more{
    border-bottom: 1px solid black;
    padding-bottom: 4px;
    font-weight: 600 !important;
}


/* TABLET */
@media only screen and (max-width: 1024px) {
    .news-cell-div{
        grid-column: span 3;
    }
}

/* MOBILE */
@media only screen and (max-width: 768px) {
    .news-cell-div > div.info, .software-cell-div > div.info{
        padding: 1vmin;
    }
    .news-cell-div{
        grid-column: span 6;
    }
    #read-more-container{
        margin-bottom: 2vmin;
    }

}
