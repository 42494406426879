.news-grid{
    --min-row-height: 320px;
    --stacked-col-max-height: 198px;
    grid-gap: 20px;
    display: grid;
    gap: 20px;
    grid-auto-flow: row;
    grid-auto-rows: minmax(var(--min-row-height),auto);
    grid-template-columns: repeat(6,1fr);
    padding-bottom: 0;
}
