.header-container{
    padding-bottom: 6vmin;
}

.title-header-div {
    text-align: center;
    max-width: 60%;
    display: flex;
    align-items: center;
    margin: auto;
    gap: 8vmin;
    flex-direction: column;
    min-height: 30vmin;
    justify-content: center;
}

.img-container-div {
    aspect-ratio: 18/9;
    height: auto;
    width: 100%;
}

.img-container-div > .images-slider {
    height: 100%;
    width: 100%;
}

#imgCover {
    margin-top: 15vmin;
}

/* MOBILE */
@media only screen and (max-width: 768px) {

    .title-header-div {
        max-width: initial
    }
}
