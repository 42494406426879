.bullet-container{
    gap: 10vmin;
    display: flex;
    flex-direction: column;
}

.bullet-container #title p{
    max-width: 70vmin;
}

.bullet-subtitle{
    max-width: 70%;
    text-align: left;
}

.list-bullet-content{
    display: flex;
    flex-direction: column;
    gap: 8vmin;
}

.list-bullet-content>*:last-child{
    margin-bottom: 8vmin;
}

.bullet-item-desc{
    max-width: 600px;
    line-height: 1.6;
}

.bullet-row>div:first-child, .bullet-row>img:first-child, .bullet-row>i:first-child{
    min-width: 32px;
    max-width: 32px;
}

.bullet-description-container{
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.bullet-container .action-div{
    max-width: 200px;
    min-width: 115px;
    padding: 1.5vmin;
    border-radius: 12px;
    height: 5vmin;
}

.bullet-container .img-bullet-container{
    display: flex;
    align-items: center;
    justify-content: center;
}

.bullet-container .img-bullet-container > div{
    max-width: 80%;
}


.background-bullet{
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    -webkit-transition: background-image 1s ease-in-out;
    transition: background-image 1s ease-in-out;
}


.img-container-div{
    aspect-ratio: 18/9;
    height: auto;
    width: 100%;
}

.img-container-div > .images-slider{
    height: 100%;
    width: 100%;
}


.bullet-container .radius-25  .swiper{
    border-radius: 25px;
}

.bullet-list-header{
    gap: 32px;
}

.list-data-content{
    transition: all 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    gap: 2vmin;
}

.list-data-content:hover{
    margin-left: -2vmin;
    margin-right: 2vmin;
}

.list-bullet-container{
    padding-top: 0;
    margin-bottom: 8vmin;
}

.list-bullet-content .list-data:last-child{
    margin-bottom: 0;
}

/* MOBILE */
@media only screen and (max-width: 768px) {

    .img-bullet-container{
        display: none;
    }

    .bullet-list-header{
        flex-direction: column;
        gap: 16px;
    }

    .bullet-list-header .justify-content_end{
        justify-content: flex-start;
    }

    .bullet-subtitle{
        max-width: 85%;
    }

    .bullet-container{
        gap: 8vmin;
    }
}

