.screenshot-gallery{
    gap: 10vmin;
    display: flex;
    flex-direction: column;
}

.screenshot-gallery #title p{
    max-width: 70vmin;
}

.screenshot-gallery-subtitle{
    max-width: 70%;
    text-align: left;
}


.img-screenshot-gallery{
    display: flex;
    align-items: center;
    justify-content: center;
}

.img-screenshot-gallery > div{
    max-width: 80%;
}

.screen-gallery {
    display: flex;
    overflow-x: auto;
    padding-top: 4vmin;
    padding-bottom: 4vmin;
    cursor: url(../../assets/images/hold_drag.svg), auto;
}

.screen-gallery-item {
    width: 50%;
    min-width: 75vmin;
    height: auto;
    /* display: flex;
    flex-direction: column; */
    background-position: top;
    background-repeat: no-repeat;
    background-size: contain;
    margin-left: 8vmin;
    transition: transform 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.screen-gallery-item:last-child{
    margin-right: 8vmin;
}

.screenshot-gallery .indiana-scroll-container--dragging {
    cursor: url(../../assets/images/freccia_slide.svg), auto !important;
}

.didascalia-container{
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.didascalia-p{
    max-width: 80%;
    margin: 0 auto;
}

.screenshot-img-container img{
    max-width: 100%;
    max-height: 50vmin;
    object-fit: cover;
}

.screenshot-img-container{
}

/* TABLET */
@media only screen and (max-width: 1024px) {
    .screen-gallery-item {
        margin-left: 4vmin;
    }

    .screen-gallery-item:last-child{
        margin-right: 4vmin;
    }
}

/* MOBILE */
@media only screen and (max-width: 768px) {

    .screenshot-gallery-subtitle{
        max-width: 85%;
    }

}

