
.section-footer-container {
    display: flex;
    margin-top: 14vmin;
    column-gap: 4vmin;
}

footer .kreosoft-logo {
    margin-bottom: 7vmin;
}

footer .section-footer-container {
    padding-bottom: 7vmin;
}

.client-footer-container {
    margin-top: 0;
}

.section-footer-column {
    flex: 1;
    text-align: left;
}

footer {
    background-color: #081722;
    padding-top: 8vmin;
    color: white;
}

footer .kreosoft-logo {
    margin-bottom: 7vmin;
}

footer p {
    line-height: 2.25;
}

footer .section-footer-container {
    padding-bottom: 7vmin;
}

footer a {
    color: white;
}

.pre-footer-img {
    height: 120vmin;
    width: 100%;
    background-size: cover;
    background-color: white;
}

.privacy-box{
    display: flex;
    flex-direction: row;
    gap: 4vmin;
    padding-bottom: 8vmin;
    align-items: center;
}

.privacy-box p{
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    line-height: normal !important;
}

.privacy-box p:first-child{
    display: flex;
    justify-content: start;
}

.privacy-box p:last-child{
    display: flex;
    justify-content: start;
}

#footer-container .header-container{
    padding-top: 10vmin;
    padding-bottom: 10vmin;
}

/* MOBILE */
@media only screen and (max-width: 768px) {

    .section-footer-container {
        flex-direction: column;
    }


    .privacy-box p:last-child{
        justify-content: flex-end;
    }

}
