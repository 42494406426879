.news-list-container-div{
    --min-row-height: 320px;
    --stacked-col-max-height: 198px;
    display: grid;
    grid-gap: 20px;
    gap: 20px;
    grid-template-columns: repeat(6,1fr);
    grid-auto-flow: row;
    grid-auto-rows: minmax(var(--min-row-height),auto);
}

.news-container-div{
    padding-top: 10vmin;
    padding-bottom: 8vmin;
    text-align: left;
}

.news-title{
    margin-bottom: 8vmin;
}

.news-subtitle{
    margin-bottom: 8vmin;
}

.closing-title-div{
    max-width: 70%;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 9vmin;
    padding-top: 10vmin;
    padding-bottom: 9vmin;
    align-items: center;
}


